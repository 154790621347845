import React from "react";
import emailjs from "@emailjs/browser";



export default function AnnoyMe() {
	const [message, setMessage] = React.useState("");
	const [status, setStatus] = React.useState("unsent");

	function changeMessage(e: React.ChangeEvent<HTMLInputElement>) {
		setMessage(e.target.value);
	}



	function sendEmail(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
        setStatus("loading")
		emailjs.send("service_ykf56hc", "template_yf2m79f", {message: message}, {
            publicKey: "H4bjRf5bvMuHROasO"
        }).then(
			function (response) {
                setStatus("success")
				console.log("Success!", response.status);
			},
			function (err) {
                setStatus("error")
				console.log("FAILED", err);
			},
		);
	}



	return (
		<div className="mb-4 w-full flex items-center justify-center group mt-5 md:mt-0">
			<div className="flex bg-white max-w-[400px] py-8 px-8 border-pink-200 group-focus-within:-translate-y-2 border-8 flex-col p-4 text-pink-200 rounded-2xl items-center justify-center gap-2 transition-all ease-in-out duration-150 group-focus-within:shadow-2xl">
				<h1 className="font-bold text-2xl">Annoy Me</h1>
				{status === "unsent" && (
					<form
						onSubmit={(e) => {
							sendEmail(e);
						}}
						className="flex md:flex-row gap-2 flex-col"
					>
						<input
							placeholder="Write Message..."
							onChange={(e) => {
								changeMessage(e);
							}}
							value={message}
							className="text-pink-500 border-pink-200 border-4 rounded-xl p-4 focus:-translate-y-2 focus:shadow-xl transition-all ease-in-out duration-150"
						/>
						<button
							type="submit"
							className="rounded-xl bg-pink-200 text-white font-bold p-4 hover:-translate-y-2 hover:bg-pink-300 transition ease-in-out duration-150"
						>
							{" "}
							Annoy{" "}
						</button>
					</form>
				)}
                {
                    status === "loading" && (
                        <span className="text-pink-400">
                             Loading...
                        </span>
                    )
                }
                {
                    status === "success" && (
                        <div className="flex flex-col items-center justify-center text-pink-400">
                            <span>Finished!</span>
                            <span>(mwah)</span>
                        </div>
                    )
                }
                {
                    status === "error" && (
                        <div className="flex flex-col text-pink-400 items-center justify-center">
                            <span>Error</span>
                            <span>There was an issue ;-;</span>
                        </div>
                    )
                }
			</div>
		</div>
	);
}
