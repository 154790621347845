import React from 'react';
import Home from './components/Home';
import { Snowfall } from "react-snowfall";
import Gallery from './components/Gallery';
import AnnoyMe from './components/AnnoyMe';

function App() {
  return (
    <div className="text-white min-h-screen bg-sky-300">
        <Snowfall />
        <Home />
        <AnnoyMe />
        <Gallery />
    </div>
  );
}

export default App;
