import React from "react";
import IMAGES from "../assets/Gallery/Gallery";
import { motion } from "framer-motion";

function Gallery() {
	const [pressed, notPressed] = React.useState(false);

	const pressButton = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		notPressed(!pressed);
	};

	return (
		<div className="w-full flex flex-col items-center justify-center mt-9">
			<button onClick={(e) => pressButton(e)} className="bg-white text-pink-300 p-4 rounded-xl hover:-translate-y-3 hover:shadow-xl transition-all ease-in-out duration-500">
				<h1 className="font-bold text-2xl">Gallery </h1>
			</button>
                { pressed ? <></> :
               <p className="text-white text-base"> (click me) </p> 
                }
			{pressed ? (
				<div className="flex flex-col pt-4 md:grid md:grid-cols-4 items-center gap-4 px-16">
					{IMAGES.map((image, index) => (
						<motion.div
							key={index}
                            initial={{ y: -10000 }}
                            animate={{ y: 0 }}
                            transition={{delay: index * 0.2, stiffness: 20, type: "spring" }}
						>
                        <div
							className="hover:shadow-xl bg-pink-300 p-2 rounded-xl flex items-center justify-center transition-all ease-in-out duration-500 hover:-translate-y-2"

                        >
							<img src={image} width={250} alt="Gallery" className="rounded-xl"/>
                            </div>
						</motion.div>

					))}
				</div>
			) : (
				<div></div>
			)}
		</div>
	);
}
export default Gallery;
