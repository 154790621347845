
const IMAGES = [
    require('./1.jpg'),
    require('./2.jpg'),
    require('./3.jpg'),
    require('./4.jpg'),
    require('./5.jpg'),
    require('./6.jpg'),
    require('./7.jpg'),
    require('./8.jpg'),
    require('./9.jpg'),
    require('./10.jpg'),
    require('./11.jpeg'),
    require('./12.jpeg'),
    require('./13.jpeg'),
    require('./14.jpeg'),
    require('./15.jpeg'),
    require('./uberdriverfrasier.jpeg'),
]
 export default IMAGES
