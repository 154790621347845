import React from "react";
import nikki from "../assets/Home.jpg";

function Home() {
	return (
		<div className="w-full h-screen flex flex-col items-center justify-center">
			<div className="p-4 rounded-xl group bg-sky-300 hover:bg-white hover:shadow-2xl hover:-translate-y-5 transition ease-in-out duration-500">
				<div className="p-8 bg-pink-300 flex rounded-xl flex-col items-center justify-center">
					<div className="rounded-xl my-4 shadow-xl bg-white flex flex-col justify-center items-center p-4 group-hover:-translate-y-2 transition-all ease-in-out duration-500">
						<h4 className="text-lg font-bold text-pink-400"> Welcome </h4>
						<p className="text-pink-400"> You're Looking cute today </p>
					</div>
					<img
						src={nikki}
						alt="Cute Nikki"
						className="rounded-full border-white border-2 group-hover:-translate-y-2 group-hover:shadow-xl duration-500"
						width={250}
						height={250}
					/>
                <div className="p-4 bg-pink-300 rounded-xl mt-4 group-hover:bg-white transition-all ease-in-out duration-500">
                    <p className="text-pink-300" > Mwah {'<'}3 </p>
				</div>
                </div>
			</div>
		</div>
	);
}

export default Home;
